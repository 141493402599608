import { inject }                     from 'aurelia-framework';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { Specification }              from 'modules/management/models/specification';

@inject(LotInterventionsRepository)
export class CopyToAnotherLotFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param lotInterventionsRepository
     */
    constructor(lotInterventionsRepository) {
        this.lotInterventionsRepository = lotInterventionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Specification}
     */
    model() {
        let model = new Specification();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         12,
            remoteSource: () => this.lotInterventionsRepository.relatedTo(viewModel.lotInterventionId),
        };

        return [
            [this.lot_intervention_id],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.next',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
