import { DialogController }           from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { BaseFormViewModel }          from 'base-form-view-model';
import { CopyToAnotherLotFormSchema } from 'modules/management/concessions/lot-groups/copy-to-another-lot-modal/form-schema';
import { SpecificationsRepository }   from 'modules/management/specifications/specifications/services/repository';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, SpecificationsRepository, DialogController, CopyToAnotherLotFormSchema)
export class CopyToAnotherLotModal extends BaseFormViewModel {

    formId = 'management.concessions.lot-groups.copy-to-another-lot-modal';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    @bindable settings = {
        title:   'form.title.chose-related-lot-to-copy-to',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.lotInterventionId = params.lotInterventionId;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.dialogController.ok(this.model.lot_intervention_id);
    }

}
